import { Injectable } from '@angular/core';
import { isBrowser } from 'environments/environment.development';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private readonly LANG_KEY = 'selectedLang';

    constructor() { }

    setActiveLang(lang: string): void {
        if (isBrowser) {
            const direction = lang === 'ar' ? 'rtl' : 'ltr';
            sessionStorage.setItem(this.LANG_KEY, JSON.stringify({ lang, direction }));
        }
    }

    getActiveLang(): { lang: string; direction: string } {
        if (isBrowser) {

            const storedLang = sessionStorage.getItem(this.LANG_KEY);
            if (storedLang) {
                try {
                    const { lang, direction } = JSON.parse(storedLang);
                    return { lang, direction };
                } catch (error) {
                    console.error('Error parsing stored language:', error);
                }
            }
        }
        // Return default language and direction if stored value is not valid
        return { lang: 'fr', direction: 'ltr' };
    }
}
