/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.project',
                title: 'Project',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/admin/dashboard',
            },
        ],
    },

    {
        id: 'cms',
        title: 'cms',
        type: 'collapsable',
        icon: 'heroicons_outline:document',
        subtitle: 'Admin Paramètres',
        // link: '',
        children: [
            {
                id: 'bloc',
                title: 'blocs',
                type: 'basic',
                // icon: 'heroicons_outline:database',
                link: '/admin/cms/bloc',
            },
            {
                id: 'page',
                title: 'pages',
                type: 'basic',
                // icon: 'heroicons_outline:database',
                link: '/admin/cms/pages',
            },
        ]
    },
];

export const compactNavigation: FuseNavigationItem[] = [...defaultNavigation];
export const futuristicNavigation: FuseNavigationItem[] = [...defaultNavigation];
export const horizontalNavigation: FuseNavigationItem[] = [

    // {
    //     id: 'Nos expertises',
    //     title: 'Nos expertises',
    //     link: '/nos-expertises',
    //     type: 'basic',
    // },
    // {
    //     id: 'Notre histoire',
    //     title: 'Notre histoire',
    //     link: '/notre-histoire',
    //     type: 'basic',
    // },
    // {
    //     id: 'CONTACTEZ-NOUS',
    //     title: 'CONTACTEZ-NOUS',
    //     link: '/contactez-nous',
    //     type: 'basic',
    // },
    // {
    //     id: 'références',
    //     title: 'Références',
    //     link: '/references',
    //     type: 'basic',
    // },
    // {
    //     id: 'Case studies',
    //     title: 'Case studies',
    //     link: '/actualites',
    //     type: 'basic',
    // },
    // {
    //     id: 'Actualités',
    //     title: 'Actualités',
    //     link: '/actualites',
    //     type: 'basic',
    // },
    {
        id: 'Nos offres',
        title: 'Nos offres',
        link: '/nos-offres',
        type: 'basic',
    },
    {
        id: 'Candidature',
        title: 'Candidature Spontanée',
        iframe: true,
        iframeUrl: 'https://inrecruitingfr.intervieweb.it/app.php?module=iframeRegister&IdForm=4135&h=&Col=1&d=&lang=en&LAC=sportcarriere&ifrm=',
        type: 'basic',
    },
];

