<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="languages">
 <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: activeLang}"></ng-container>

</button>

<!-- Language menu -->
<mat-menu
    [xPosition]="'before'"
    #languages="matMenu">
    <ng-container *ngFor="let lang of $any(availableLangs); trackBy: trackByFn">
        <button
            mat-menu-item
            (click)="setActiveLang(lang.id)">
            <span class="flex items-center">
                <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang.id, showFlag: true}"></ng-container>
                <span class="ml-3">{{lang.label}}</span>
                    <!-- @if(lang.id == 'ar'){
                        <span >{{lang.id}}</span>
                    <span class="ml-3">{{'العربية'}}</span>
                    }@else{
                        <span >{{lang.id}}</span>
                        <span [class]="isScrolled?'ml-3 text-black': 'ml-3 text-white'" >{{lang.label}}</span>
                    } -->


            </span>
        </button>
    </ng-container>
</mat-menu>

<!-- Flag image template -->
<ng-template
    let-lang
    let-showFlag="showFlag"
    #flagImage>
    <span *ngIf="!showFlag" [class]="isScrolled ? 'capitalize text-lg text-black font-normal' : 'capitalize text-lg text-white font-normal'">
        {{activeLang}}
    </span>
    <span *ngIf="!showFlag" [class]="isScrolled?'text-black': 'text-white'">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="2" viewBox="0 0 14 8">
            <path d="M12.25 1.0293L7 6.50615L1.75 1.0293" stroke="#394149" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
    </span>

    <span class="relative flex items-center  rounded-sm overflow-hidden" *ngIf="showFlag">
        <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>


        <img
            class="w-full"
            [src]="'assets/images/flags/' + flagCodes[lang]?.toUpperCase() + '.svg'"
            [alt]="'Flag image for ' + lang">
    </span>
</ng-template>
