import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { SnackService } from '@fuse/services/snack/snack.service';
import { UowService } from 'app/core/http-services/uow.service';
import { LocalService } from 'app/core/user/local.service';
import { defaultNavigation } from 'app/mock-api/common/navigation/data';
import { map, of, shareReplay, switchMap, tap } from 'rxjs';

export const AdminGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router = inject(Router);
    const snack = inject(SnackService);
    const menuItems = defaultNavigation;
    const uow = inject(UowService);
    const user = toObservable(uow.session.user);

    const isAdmin = uow.session.user()?.id === 1;

    if (isAdmin) {
        return of(true);
    }

    // Check the authentication status
    // return user.pipe(
    //     switchMap(user => uow.core.roles.apiRolesGetModulesGet().pipe(
    //         switchMap(modules => {
    //             uow.session.modules.set(modules);

    //             const modulesChilds = modules.concat( modules.map(e => e.children).flat());

    //             const links = modules.concat(modulesChilds)
    //                 .map(e => menuItems.find(m => m.id === e.name))
    //                 .filter(e => !!e)
    //                 .map(e => e)
    //                 ;

    //             const all  = links.concat( links.map(e => e.children).flat()).filter(e => !!e.link).map(e => e.link);

    //             const routeReqExist = !!links.length && all.some(e => state.url.startsWith(e));


    //             if (!routeReqExist) {
    //                 const firstLink = all.at(0);

    //                 if (!!firstLink) {
    //                     router.navigate([firstLink]);
    //                     return of(true);
    //                 }
    //                 router.navigate(['/']);
    //                 snack.open(`[CA] Vous n\'avez pas les droits pour accéder à cette page ${state.url}`, 'OK');
    //                 return of(false);
    //             }

    //             // Allow the access
    //             return of(true);
    //         }),
    //     )),
    // );
};
