<!-- Button -->

<button *ngIf="!isSignin()" mat-icon-button [routerLink]="['/auth/sign-in']">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user()?.avatar" [src]="user()?.avatar">
        <mat-icon *ngIf="!showAvatar || !user()?.avatar" >person</mat-icon>

    </span>
</button>

<button *ngIf="isSignin()" mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">

        <!-- <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user()?.avatar"  [src]="'/assets/images/avatars/male-01.jpg'"> -->

        <mat-icon [ngClass]="{'text-black': isScrolled, 'text-white': !isScrolled}"  >person</mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'mr-px mb-px': !showAvatar || !user()?.avatar,
                          'bg-green-500': user()?.active,
                          'bg-red-500': user()?.active === false,}"></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>connecté en tant que</span>
            <span class="mt-1.5 text-md font-medium">{{user()?.firstname}} {{user()?.lastname}}</span>

        </span>
    </button>


    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon >arrow_forward</mat-icon>
        <span>{{'se deconnecter' | transloco}}</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
