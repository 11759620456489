<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<fuse-vertical-navigation class="dark bg-primary print:hidden" [mode]="'over'" position="right" #menu
    [name]="'mainNavigation'" [navigation]="navigation.horizontal" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 pt-6 px-8 cursor-pointer" [routerLink]="['']">
            <img class="w-8" src="assets/images/logo/logo-white.png" alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>
<!-- Wrapper -->
<div class="relative  flex flex-col  w-full min-w-0">

    <!-- Header -->
    <div class=" fixed flex flex-0 justify-center items-center w-full h-24  z-49 print:hidden"
        [ngClass]="{'bg-white': isScrolled}">
        <div class="flex w-11/12 ">
            <!-- Logo -->
            <div class="flex flex-col cursor-pointer" [routerLink]="['/']">
                <img class="w-32 " [src]="'assets/images/logo/' + (isScrolled ? 'meent.svg' : 'meent-white.svg')">

                <p class="text-[14px] uppercase font-light"
                    [ngClass]="{'text-black': isScrolled, 'text-white': !isScrolled}">
                    Join the passion</p>
            </div>

            <span class="flex-1"> </span>
            <!-- Horizontal navigation -->

            <!-- Components -->
            <div class="flex items-center gap-4 ">
                <fuse-horizontal-navigation *ngIf="!isScreenSmall"
                    [ngClass]="{'text-black': isScrolled, 'text-white': !isScrolled}" [name]="'mainNavigation'"
                    [navigation]="navigation.horizontal.slice(-2)"></fuse-horizontal-navigation>
                <languages [isScrolled]="isScrolled"></languages>

                <!-- <user [isScrolled]="isScrolled"></user> -->
                 <div></div>

                <button mat-icon-button onclick="showMenu()">
                    <mat-icon [ngClass]="{'text-black': isScrolled, 'text-white': !isScrolled}" [svgIcon]="'heroicons_outline:bars-3-bottom-right'"></mat-icon>

                </button>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <nav id="my-menu" #menuS (click)="redirectTo$.next($event)"
    class=" h-screen translate-x-1 w-screen z-50 fixed transform -translate-y-full transition-transform  duration-500 ease-out hidden" [innerHTML]="menuHTML() | sanitizeHtml"></nav>
</div>



<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
