import { DOCUMENT } from '@angular/common';
import { Component, inject, Inject, OnInit, Renderer2 } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SnackComponent } from '@fuse/services/snack/snack.component';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { environment, isBrowser } from 'environments/environment.development';
import { filter, tap } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        SnackComponent,
        RouterOutlet,
    ],
})
export class AppComponent implements OnInit {
    readonly router = inject(Router);
    readonly renderer = inject(Renderer2);
    readonly document = inject(DOCUMENT);
    readonly splashScreen = inject(FuseSplashScreenService);

    isLoaded = true;

    ngOnInit(): void {
        // if (isBrowser) {
        //     window.addEventListener('DOMContentLoaded', () => {
        //         // After all assets and JS files are fully loaded, hide the loading screen
        //         setTimeout(() => {

        //             this.isLoaded = true;
        //             this.splashScreen.hide()
        //         }, 100);
        //       });
        // }
    }

    readonly routesChanges$ = toSignal(this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        tap(_ => this.splashScreen.show()),
        tap((event: NavigationEnd) => {
            const routeData = this.router.routerState.root.firstChild?.snapshot.data;
            // const routeData1 = this.router.routerState;
            // Access the data properties from routeData object

            // console.log('routeData', routeData);

            if (routeData.isCMS === true || event.url === '/' /*|| this.routes.some(route => event.url.includes(route))*/) {
                // console.error('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', event.url, routeData.isCMS === true);
                const existingLinkElement = this.document.head.querySelector('link[href*="/cms.css"]');

                // console.log('existingLinkElement', existingLinkElement);

                if (!existingLinkElement) {
                    // Remove the existing link element
                    const timestamp = Date.now();
                    const linkElement = this.renderer.createElement('link');
                    this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
                    this.renderer.setAttribute(linkElement, 'href', `${environment.url}/cms.css?time=${timestamp}`);
                    this.renderer.appendChild(this.document.head, linkElement);
                }

            } else {
                const existingLinkElement = this.document.head.querySelector('link[href*="/cms.css"]');
                if (existingLinkElement) {
                    // Remove the existing link element
                    this.renderer.removeChild(this.document.head, existingLinkElement);
                }
            }
        }),
        tap(_ => this.isLoaded && this.splashScreen.hide()),
    ));
}
