import { NGX_MONACO_EDITOR_CONFIG } from "ngx-monaco-editor";
import { tailwindClasses } from "./tailwindClasses";

// import * as monaco from 'monaco-editor'

export const monacoConfig = {
    provide: NGX_MONACO_EDITOR_CONFIG,
    useValue: {
        baseUrl: 'assets', // path to the Monaco Editor library
        // https://microsoft.github.io/monaco-editor/docs.html#enums/editor.EditorOption.html
        defaultOptions: {
            scrollBeyondLastLine: false,
            minimap: { enabled: false },
            automaticLayout: true,
            folding: true,
            theme: 'vs-dark',
            lineNumbers: 'off',
            autoClosingBrackets: 'always', // Enable auto-closing brackets
            autoClosingQuotes: 'always', // Enable auto-closing quotes
        } , // global editor options
        onMonacoLoad: () => {
            const monaco = (window as any).monaco;

            // Load HTML language features
            monaco.languages.html.htmlDefaults.setOptions({
                format: {
                    indentInnerHtml: true,
                    wrapLineLength: 120,
                    tabSize: 2,
                    insertSpaces: true,
                    unformatted: 'wbr',
                    contentUnformatted: 'pre,code,textarea',
                    indentHandlebars: false,
                    endWithNewline: false,
                    extraLiners: 'head, body, /html',
                    wrapAttributes: 'auto',
                    preserveNewLines: true,
                    maxPreserveNewLines: 2,

                  },
                suggest: {
                    html5: true,
                },
            });

            // Add Tailwind CSS IntelliSense
            monaco.languages.registerCompletionItemProvider('html', {
                provideCompletionItems: (model, position) => {
                    const word = model.getWordUntilPosition(position);
                    const range = new monaco.Range(
                      position.lineNumber,
                      word.startColumn,
                      position.lineNumber,
                      word.endColumn
                    );

                    const suggestions = tailwindClasses.map((className) => ({
                      label: className,
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: className,
                      documentation: `Tailwind CSS class: ${className}`,
                      range: range,
                    }));

                    return { suggestions: suggestions };
                  },
            });
        },
    },
};
