import { CommonModule, NgIf } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewEncapsulation, inject, signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseHorizontalNavigationComponent, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UowService } from 'app/core/http-services/uow.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { environment } from 'environments/environment.development';
import { delay, map, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { SanitizeHtml } from "@fuse/pipes/sanitize-html.pipe";
import { set } from 'lodash-es';

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        FuseLoadingBarComponent,
        FuseHorizontalNavigationComponent,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        FuseVerticalNavigationComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        RouterOutlet,
        TranslocoModule,
        SanitizeHtml
    ],
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
    readonly uow = inject(UowService);
    readonly router = inject(Router);
    readonly _translocoService = inject(TranslocoService);


    readonly isAdmin = this.uow.session.isAdmin;
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    readonly menuS = viewChild.required<ElementRef<HTMLDivElement>>('menuS');


    readonly menuHTML = toSignal(this.uow.core.blocTemplates.apiSimpleBlocTemplatesGetByNameGet('menu').pipe(
        map(e => e.replace(/environment.url/g, environment.url)),
        // tap(e => this.footer.set(e.replace(/environment.url/g, environment.url))),
    ));

    readonly #langueFc = toSignal(this._translocoService.langChanges$.pipe(
            startWith(this._translocoService['lang'].value),
            delay(100),
            tap(langue => {
                // Get all elements with the 'data-langue' attribute
                this.menuS().nativeElement.querySelectorAll('[data-langue]').forEach((element: HTMLElement) => {
                    const _langue = JSON.parse(element.getAttribute('data-langue') ?? '{}') as { [key: string]: string };

                    element.innerText = _langue[langue] || Object.values(_langue).filter(v => !!v)?.at(0) || '';
                });
            }),
    ));

    readonly redirectTo$ = new Subject<any>();
    readonly #redirectTo = toSignal(this.redirectTo$.pipe(
        tap(e => {
            const element: HTMLElement = e.target;
            // if (element.nodeName === 'A') {
            e.preventDefault();
            const link = element.getAttribute('href');
            const target = element.getAttribute('target');

            if (link.startsWith('#')) {
                return;
            }

            if (link.startsWith('mailto:')) {
                // Handle email link
                window.location.href = link;
                return;
            }

            if (link.startsWith('tel:')) {
                // Handle phone link
                window.location.href = link;
                return;
            }

            if (target === '_blank') {
                // Open link in a new tab
                window.open(link, '_blank');
                return;
            }

            const url = new URL(link, window.location.origin); // Base URL to handle relative URLs
            const searchParams = new URLSearchParams(url.search);

            const queryParams: any = {};
            searchParams.forEach((value, key) => {
                queryParams[key] = value;
            });

            this.router.navigate([url.pathname], { queryParams });
        }),
    ));

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Execute the line on initial load
        this.isScrolled = (this.router.url === '/' || this.router.url === '') ? false : true;

        // Listen for URL changes
        this.router.events
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isScrolled = (event.url === '/' || event.url === '') ? false : true;
            }
            });
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    isScrolled = true;

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (this.router.url !== '/') {
            return;
        }
        // console.warn(this.router.url);
        this.isScrolled = window.scrollY > 50;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
