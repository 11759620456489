import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AdminGuard } from './core/auth/guards/admin.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: '' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '' },

    // Auth routes for guests
    {
        path: 'auth',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: { isCMS: false, layout: 'empty' },
        children: [
            // { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            // { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
        ]
    },

    // Admin routes
    {
        path: 'admin',
        canActivate: [AuthGuard, AdminGuard],
        canActivateChild: [AuthGuard],
        data: { isCMS: false },
        component: LayoutComponent,
        resolve: { initialData: initialDataResolver },
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'cms' },
            { path: 'cms', loadChildren: () => import('app/modules/admin/cms/cms.routes') },
        ]
    },

    // public routes
    {
        path: '',
        component: LayoutComponent,
        resolve: { initialData: initialDataResolver },
        data: { layout: 'modern', isCMS: true },
        children: [
            { path: '', loadChildren: () => import('app/modules/landing/home/home.routes') },
            { path: 'offres', loadChildren: () => import('app/modules/admin/cms/components/vacancies/vacancies.routes') },
            { path: 'question', loadChildren: () => import('app/modules/admin/cms/components/question/question.routes') },
            { path: 'article', loadChildren: () => import('app/modules/admin/cms/components/article-caroussel/article.routes') },
            { path: 'articles', loadChildren: () => import('app/modules/admin/cms/components/articles/articles.routes') },
            { path: ':id', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ]
    },
];
