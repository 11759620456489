export const tailwindClasses = [
    "25em",
    "5em",
    "css",
    "text-default",
    "text-secondary",
    "text-hint",
    "text-disabled",
    "divider",
    "bg-card",
    "bg-default",
    "ring-bg-card",
    "bg-hover",
    "theme-default",
    "theme-brand",
    "theme-teal",
    "theme-rose",
    "theme-purple",
    "theme-amber",
    "light",
    "dark",
    "pointer-events-none",
    "visible",
    "collapse",
    "static",
    "fixed",
    "absolute",
    "relative",
    "sticky",
    "inset-0",
    "-bottom-8",
    "-left-1",
    "25rem",
    "-left-14",
    "5rem",
    "-right-1",
    "-right-16",
    "-right-7",
    "75rem",
    "-top-12",
    "-top-16",
    "bottom-0",
    "left-0",
    "right-0",
    "right-5",
    "top-0",
    "top-1",
    "z-10",
    "z-20",
    "z-49",
    "z-90",
    "z-99",
    "z-999",
    "col-span-1",
    "col-span-2",
    "col-span-3",
    "col-span-6",
    "m-0",
    "m-1",
    "m-3",
    "-mx-4",
    "-mx-6",
    "mx-1",
    "375rem",
    "mx-2",
    "mx-3",
    "mx-4",
    "mx-auto",
    "my-0",
    "125rem",
    "my-1",
    "my-10",
    "my-2",
    "my-20",
    "my-3",
    "my-4",
    "my-5",
    "my-8",
    "my-px",
    "-mb-px",
    "-ml-0",
    "-ml-1",
    "-ml-2",
    "-ml-px",
    "-mr-1",
    "-mr-2",
    "-mr-5",
    "-mr-px",
    "-mt-2",
    "-mt-5",
    "-mt-6",
    "mb-1",
    "mb-16",
    "mb-2",
    "mb-20",
    "mb-3",
    "mb-4",
    "mb-6",
    "mb-8",
    "mb-px",
    "ml-0",
    "ml-1",
    "ml-10",
    "ml-2",
    "625rem",
    "ml-3",
    "ml-4",
    "ml-5",
    "ml-6",
    "ml-auto",
    "mr-0",
    "mr-1",
    "mr-2",
    "mr-3",
    "mr-4",
    "mr-6",
    "mr-auto",
    "mr-px",
    "mt-0",
    "mt-1",
    "mt-12",
    "mt-2",
    "mt-3",
    "mt-4",
    "mt-5",
    "mt-6",
    "mt-8",
    "mt-auto",
    "mt-px",
    "line-clamp-1",
    "line-clamp-2",
    "block",
    "inline-block",
    "inline",
    "flex",
    "inline-flex",
    "table",
    "grid",
    "contents",
    "hidden",
    "h-1",
    "h-10",
    "h-100",
    "h-11",
    "h-12",
    "h-14",
    "h-16",
    "h-2",
    "h-20",
    "h-24",
    "h-3",
    "h-4",
    "h-400",
    "h-5",
    "h-6",
    "h-7",
    "h-72",
    "h-8",
    "h-auto",
    "h-full",
    "max-h-128",
    "max-h-400",
    "min-h-20",
    "min-h-400",
    "min-h-6",
    "min-h-7",
    "min-h-full",
    "w-1",
    "w-10",
    "w-100",
    "w-12",
    "w-14",
    "w-16",
    "w-2",
    "w-20",
    "w-24",
    "w-28",
    "w-3",
    "w-30",
    "w-36",
    "w-4",
    "w-40",
    "w-48",
    "w-5",
    "w-6",
    "w-60",
    "w-7",
    "w-8",
    "w-80",
    "w-auto",
    "w-full",
    "w-screen",
    "min-w-0",
    "min-w-10",
    "min-w-4",
    "min-w-screen",
    "max-w-2xl",
    "max-w-3",
    "max-w-36",
    "max-w-360",
    "max-w-60",
    "max-w-80",
    "max-w-max",
    "max-w-screen-xl",
    "max-w-xs",
    "flex-0",
    "flex-1",
    "flex-auto",
    "shrink",
    "shrink-0",
    "border-collapse",
    "-translate-x-full",
    "translate-x-0",
    "rotate-90",
    "-scale-x-1",
    "transform",
    "animate-spin-slow",
    "cursor-move",
    "cursor-pointer",
    "resize",
    "list-inside",
    "list-decimal",
    "list-none",
    "grid-flow-row",
    "grid-cols-1",
    "grid-cols-2",
    "grid-cols-3",
    "flex-row",
    "flex-col",
    "flex-col-reverse",
    "flex-wrap",
    "place-content-center",
    "items-start",
    "items-end",
    "items-center",
    "items-baseline",
    "justify-start",
    "justify-end",
    "justify-center",
    "justify-between",
    "justify-items-start",
    "gap-1",
    "gap-2",
    "gap-3",
    "gap-4",
    "gap-5",
    "gap-6",
    "gap-8",
    "-space-x-1",
    "space-x-0",
    "space-x-1",
    "space-x-2",
    "space-x-3",
    "space-x-4",
    "space-y-1",
    "space-y-2",
    "divide-y",
    "place-self-center",
    "overflow-auto",
    "overflow-hidden",
    "overflow-y-auto",
    "overscroll-y-contain",
    "truncate",
    "text-ellipsis",
    "whitespace-nowrap",
    "rounded",
    "rounded-2xl",
    "rounded-full",
    "rounded-lg",
    "rounded-md",
    "rounded-sm",
    "rounded-b",
    "rounded-l-lg",
    "border",
    "border-0",
    "border-2",
    "border-b",
    "border-l",
    "border-r",
    "border-t",
    "border-dashed",
    "border-gray-200",
    "border-gray-300",
    "7",
    "border-gray-500",
    "border-gray-700",
    "border-green-500",
    "border-primary",
    "border-sky-500",
    "border-slate-300",
    "border-warn",
    "border-r-gray-300",
    "border-r-gray-500",
    "border-opacity-100",
    "bg-accent-100",
    "bg-accent-600",
    "bg-amber-100",
    "bg-amber-200",
    "bg-amber-50",
    "bg-amber-500",
    "bg-black",
    "1",
    "bg-blue-100",
    "bg-blue-200",
    "bg-blue-50",
    "bg-blue-500",
    "bg-blue-600",
    "bg-gray-100",
    "bg-gray-200",
    "bg-gray-300",
    "bg-gray-400",
    "bg-gray-50",
    "bg-gray-500",
    "bg-gray-600",
    "bg-gray-700",
    "bg-gray-800",
    "bg-gray-900",
    "bg-green-100",
    "bg-green-200",
    "bg-green-50",
    "bg-green-500",
    "bg-indigo-500",
    "bg-indigo-600",
    "bg-indigo-800",
    "bg-orange-500",
    "bg-pink-500",
    "bg-primary",
    "bg-primary-100",
    "bg-primary-50",
    "bg-primary-600",
    "bg-primary-700",
    "bg-purple-500",
    "bg-red-100",
    "bg-red-200",
    "bg-red-50",
    "bg-red-500",
    "bg-red-600",
    "bg-teal-500",
    "bg-teal-600",
    "bg-transparent",
    "bg-warn-100",
    "bg-warn-50",
    "bg-warn-600",
    "bg-white",
    "bg-yellow-500",
    "bg-yellow-700",
    "bg-opacity-5",
    "bg-opacity-60",
    "6",
    "bg-opacity-90",
    "9",
    "object-cover",
    "object-center",
    "p-0",
    "p-10",
    "p-16",
    "p-2",
    "p-3",
    "p-4",
    "p-5",
    "p-6",
    "p-8",
    "px-1",
    "px-16",
    "px-2",
    "px-3",
    "px-4",
    "px-5",
    "px-6",
    "px-7",
    "px-8",
    "py-0",
    "py-1",
    "py-12",
    "py-15",
    "py-2",
    "py-20",
    "py-3",
    "py-4",
    "py-5",
    "py-6",
    "py-8",
    "py-px",
    "pb-0",
    "pb-1",
    "pb-6",
    "pb-8",
    "pl-2",
    "pl-5",
    "pl-6",
    "pr-2",
    "pr-4",
    "pr-6",
    "pt-4",
    "pt-5",
    "pt-6",
    "pt-8",
    "text-center",
    "font-sans",
    "text-2xl",
    "text-3xl",
    "text-4xl",
    "text-5xl",
    "text-7xl",
    "text-base",
    "875rem",
    "text-lg",
    "text-md",
    "8125rem",
    "text-sm",
    "text-xl",
    "text-xs",
    "font-bold",
    "font-extrabold",
    "font-light",
    "font-medium",
    "font-semibold",
    "uppercase",
    "italic",
    "leading-10",
    "leading-4",
    "leading-5",
    "leading-6",
    "leading-7",
    "leading-none",
    "leading-normal",
    "leading-relaxed",
    "625",
    "leading-tight",
    "25",
    "tracking-tight",
    "025em",
    "tracking-wider",
    "05em",
    "text-accent",
    "text-accent-600",
    "text-accent-800",
    "text-amber-500",
    "text-blue-50",
    "text-blue-500",
    "text-blue-600",
    "text-current",
    "text-gray-100",
    "text-gray-200",
    "text-gray-400",
    "text-gray-50",
    "text-gray-500",
    "text-gray-600",
    "text-gray-700",
    "text-gray-900",
    "text-green-50",
    "text-green-500",
    "text-indigo-50",
    "text-on-accent",
    "text-on-primary",
    "text-on-warn",
    "text-primary",
    "text-primary-400",
    "text-primary-500",
    "text-primary-600",
    "text-primary-700",
    "text-primary-800",
    "text-red-600",
    "text-warn",
    "text-warn-600",
    "text-warn-800",
    "text-white",
    "text-opacity-12",
    "text-opacity-38",
    "38",
    "text-opacity-60",
    "underline",
    "overline",
    "line-through",
    "no-underline",
    "opacity-0",
    "opacity-100",
    "opacity-12",
    "opacity-25",
    "opacity-50",
    "opacity-80",
    "8",
    "shadow",
    "shadow-2xl",
    "shadow-lg",
    "shadow-md",
    "shadow-sm",
    "outline",
    "ring",
    "ring-1",
    "ring-2",
    "ring-4",
    "ring-inset",
    "ring-accent-400",
    "ring-amber-400",
    "ring-black",
    "ring-blue-400",
    "ring-gray-400",
    "ring-gray-800",
    "ring-green-400",
    "ring-primary",
    "ring-primary-400",
    "ring-primary-50",
    "ring-red-400",
    "ring-warn-400",
    "ring-opacity-10",
    "ring-offset-1",
    "ring-offset-gray-800",
    "blur",
    "filter",
    "transition",
    "4",
    "transition-transform",
    "duration-300",
    "duration-400",
    "ease-drawer",
    "ease-in",
    "ease-out",
    "icon-size-24",
    "icon-size-4",
    "icon-size-5",
    "icon-size-6",
    "icon-size-7",
    "hover",
    "sm",
    "even",
    "focus",
    "group",
    "group-hover",
    "peer",
    "peer-hover",
    "print",
    "md",
    "lg",
    "xl"
]
